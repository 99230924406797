import React from "react";
import { Grid } from "@chakra-ui/react";
import { BottomMenu } from "./BottomMenu";

type PageProps = {
  children: JSX.Element;
}

export const Page: React.FC<PageProps> = ({ children }) => {
  return (
    <Grid
      h="100vh"
      overflow="auto"
      boxSizing="border-box"
      bg="canelaMain.500"
      pb="78px"
    >
      {children}
      <BottomMenu />
    </Grid>
  );
}