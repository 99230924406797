import { Grid, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { observer } from "mobx-react-lite";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import generalPointsIcon from '../assets/general_points.svg';
import spendablePointsIcon from '../assets/spendable_icon.svg';
import { LangObj, tierMap } from "../shared";

const ln: { [key: string]: LangObj } = {
  generalPoints: {
    es: 'Puntos Generales',
    en: 'General Points'
  },
  redeemablePoints: {
    es: 'Puntos Canjeables',
    en: 'Redeemable Points'
  },
  redeemableMessage: {
    es: 'para llegar a la siguiente recompensa',
    en: 'to reach the next reward'
  },
  generalPointsMessage: {
    es: 'para llegar a',
    en: 'to reach'
  }
}

export const ProgressSection: React.FC<{ lang: string }> = observer(({ lang }) => {
  const user = authStore.user;
  useEffect(() => {
    if (!user) {
      authStore.getUser();
    }
  }, [ user ]);
  const currentStructure = user?.tiers.find((t) => t?.structure.id === 'canela_tier_st');
  let val = currentStructure?.next ? 0 : 100
  if (currentStructure?.next) {
    val = 100 * Number(user?.score) / (currentStructure.threshold + 2500);
  }
  const nextTier = currentStructure?.next ? currentStructure?.next : {
    id: currentStructure?.id + '2',
    name: currentStructure?.name,
    points: currentStructure?.threshold || 0,
  }
  const pointsForNextTier = Math.max(Number(currentStructure?.threshold) + 2500 - (user?.score || 0), 0);
  const spendablePercent = 100 * Number(user?.spendable) / 500;
  return (
    <Grid mt="1em" templateColumns="1fr 1fr" borderRadius="24px" bg="black">
      <Grid position="relative" p="1em" w="160px">
        <CircularProgressbarWithChildren
          value={val}
          strokeWidth={6}
          circleRatio={0.7}
          styles={{
            ...buildStyles({
              rotation: 0.65,
              strokeLinecap: 'butt',
              pathColor: '#D12222',
              trailColor: '#ffffff'
            }),
          }}
        >
          <Grid pb="1.5em" justifyItems="center">
            <Image w="22px" src={generalPointsIcon} />
            <Text fontSize="10px" variant="white">{ln.generalPoints[lang]}</Text>
          </Grid>
        </CircularProgressbarWithChildren>
        <Grid position="absolute" w="100%" bottom="1.5em">
          <Text textAlign="center" fontSize="22px" variant="white">{user?.score}</Text>
          <Text textAlign="center" fontSize="9px" variant="white">{pointsForNextTier} {ln.generalPointsMessage[lang]} {tierMap[nextTier.id]?.[lang]}</Text>
        </Grid>
      </Grid>
      <Grid position="relative" p="1em" w="160px">
        <CircularProgressbarWithChildren
          value={Math.min(spendablePercent, 100)}
          strokeWidth={6}
          circleRatio={0.7}
          styles={{
            ...buildStyles({
              rotation: 0.65,
              strokeLinecap: 'butt',
              pathColor: '#D12222',
              trailColor: '#ffffff'
            }),
          }}
        >
          <Grid pb="1.5em" justifyItems="center">
            <Image w="22px" src={spendablePointsIcon} />
            <Text fontSize="10px" variant="white">{ln.redeemablePoints[lang]}</Text>
          </Grid>
        </CircularProgressbarWithChildren>
        <Grid position="absolute" w="100%" bottom={lang === 'en' ? "1.5em" : '0.5em'}>
          <Text textAlign="center" fontSize="22px" variant="white">{user?.spendable || 0}</Text>
          <Text textAlign="center" fontSize="9px" variant="white">{Math.max(500 - Number(user?.spendable), 0)} {ln.redeemableMessage[lang]}</Text>
        </Grid>
      </Grid>
    </Grid>
  )
});