export type LangObj = {
  [key: string]: string;
}

export const tierMap: { [key: string]: LangObj } = {
  cn_bronze: {
    es: 'Bronce',
    en: 'Bronze',
  },
  cn_silver: {
    es: 'Plata',
    en: 'Silver'
  },
  cn_gold: {
    es: 'Oro',
    en: 'Gold',
  },
  cn_platinum: {
    es: 'Platino',
    en: 'Platinum',
  },
  cn_diamond: {
    es: 'Diamante',
    en: 'Diamond',
  },
}