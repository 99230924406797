import React, { useState } from "react";
import { Page } from "../components/Page";
import { Grid, Image, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import sectionLogo from '../assets/canela-recompensas.svg';
import { RewardHub } from "../components/RewardHub";
import { StoreHub } from "../components/StoreHub";

const availableLangs = ['en', 'es'];
type TabProps = {
  [key: string]: string
}

export const MyHub: React.FC = () => {
  const tabs: TabProps[] = [
    {
      es: 'Hub de Recompensas',
      en: 'Rewards Hub'
    },
    {
      es: 'Tienda',
      en: 'Store'
    },
    {
      es: 'Eventos',
      en: 'Events'
    },
  ];
  const [lang, setLang] = useState('en');
  const queryParameters = new URLSearchParams(window.location.search);
  const langParam = queryParameters.get('lang') ?? 'en';
  if (availableLangs.includes(langParam.toLowerCase()) && lang !== langParam) {
    setLang(langParam)
  }
  return (
    <Page>
      <Grid pt="10px" templateRows="auto auto 1fr">
        <Image h="45px" justifySelf="center" src={sectionLogo} />
        <Grid mt="16px" px="16px">
          <Tabs variant="unstyled">
            <TabList  placeContent="center" justifyItems="center">
              {
                tabs.map((t) => 
                  <Tab
                  key={t[lang]}
                  color="canelaLight.500"
                  _selected={
                    {
                      color: 'canelaLight.100',
                      borderBottom: '1px solid var(--chakra-colors-canelaLight-100)'
                    }
                  }
                >
                  {t[lang]}
                </Tab>
              )
              }
            </TabList>
            <TabPanels>
              <TabPanel>
                <RewardHub lang={lang} />
              </TabPanel>
              <TabPanel>
                <StoreHub lang={lang} />
              </TabPanel>
              <TabPanel>
                <h1>TODO 3</h1>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Grid>
      </Grid>
    </Page>
  )
}