import { makeAutoObservable } from 'mobx';
import { AntavoImage } from './shared/Image';

type ChallengeProgressCriteria = {
  action: string;
  message: string;
  threshold: number;
  current: number;
  is_completed: boolean;
}

type ChallengeProgress = {
  overall: number;
  criteria: ChallengeProgressCriteria[];
}

export interface Challenge {
  id: string;
  title: string;
  description: string;
  image: AntavoImage;
  points: number;
  progress: ChallengeProgress;
  url?: string;
  cta_label: string;
  completions: number;
  ends_at: string;
  starts_at: string;
  tags: string[];
}

class ChallengesStore {
  challenges: Challenge[] = [];
  isLoading: boolean = false;
  rewardApiUrl?: string = process.env.REACT_APP_REWARD_API_URL
  customerUrl?: string = process.env.REACT_APP_CUSTOMER_API_URL
  constructor() {
    makeAutoObservable(this);
  }

  async getChallenges(customerId: string, acceptLang?: string) {
    this.isLoading = true;
    if (!this.rewardApiUrl) {
      console.error('process.env.REACT_APP_REWARD_API_URL is not defined and it is needed by the challenge store');
      return;
    }
    const res = await fetch(
      `${this.rewardApiUrl}customer/${customerId}/challenges`,
      {
        headers: {
          'Accept-Language': acceptLang ? acceptLang : 'en'
        }
      }
    );
    const json = await res.json();
    if (json.data) {
      this.challenges = json.data;
    }
    this.isLoading = false;
    return this.challenges;
  }

  async watchVideo(customerId: string, url: string, acceptLang?: string) {
    this.isLoading = true;
    if (!this.customerUrl) {
      console.error('process.env.REACT_APP_AUTH_API_URL is not defined and it is needed by the auth store');
      return;
    }
    const res = await fetch(
      `${this.customerUrl}customer/event`,
      {
        headers: {
          'Accept-Language': acceptLang ? acceptLang : 'en'
        },
        method: 'POST',
        body: JSON.stringify({
          customer: customerId,
          action: 'video',
          data: {
            url
          }
        })
      }
    );
    const json = await res.json();
    this.isLoading = false;
    return json;
  }
}

export const challengesStore = new ChallengesStore();