import { makeAutoObservable } from 'mobx';

interface Activity {
  id: string;
  action: string;
  label: string;
  points: number;
  timestamp: string;
}

class ActivityStore {
  activities: Activity[] = [];
  isLoading: boolean = false;
  customerApiUrl?: string = process.env.REACT_APP_CUSTOMER_API_URL;
  constructor() {
    makeAutoObservable(this);
  }

  async getActivities(customerId: string, lang?: string) {
    this.isLoading = true;
    if (!this.customerApiUrl) {
      console.error('process.env.REACT_APP_CUSTOMER_API_URL is not defined and it is needed by the activity store');
      return;
    }
    const res = await fetch(
      `${this.customerApiUrl}/${customerId}/activities`,
      {
        headers: {
          'Accept-Language': lang ? lang : 'en'
        }
      }
    );
    const json = await res.json();
    if (json.data) {
      this.activities = json.data;
    }
    this.isLoading = false;
    return this.activities;
  }
}

export const activityStore = new ActivityStore();