import { Grid } from "@chakra-ui/react";
import { differenceInSeconds } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import YouTube from "react-youtube";
import { authStore } from "../store/AuthStore";
import { challengesStore } from "../store/ChallengesStore";

type VideoPlayerProps = {
  url: string;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = observer(({ url }) => {
  const id = url.split('v=')[1].slice(0, 11);
  const [videoDuration, setVideoDuration] = useState(0);
  const [startedAt, setStartedAt] = useState<Date | undefined>(undefined);
  const [secondsWatched, setSecondsWatched] = useState(0);
  const percent = 100 * secondsWatched / videoDuration;
  const user = authStore.user;
  useEffect(() => {
    if (percent >= 80) {
      console.log('wohoo you watched the whole thing');
      if (user) {
        console.log('sending video watch to antavo!');
        challengesStore.watchVideo(user.id, url);
      }
    }
    if (!user) {
      authStore.getUser();
    }
  }, [percent, user, url])
  console.log(`${Math.round(percent)}% watched`);
  return (
    <Grid>
      <YouTube
        opts={{
          width: '350',
          height: '250',
          playerVars: {
            autoplay: 0
          }
        }}
        videoId={id}
        onEnd={() => {
          if (startedAt) {
            const now = new Date();
            const sec = Math.abs(differenceInSeconds(startedAt, now));
            setSecondsWatched(sec + secondsWatched)
          }
        }}
        onReady={async (e) => {
          const n = await e.target.getDuration()
          setVideoDuration(n)
        }}
        onPlay={() => {
          setStartedAt(new Date());
        }}
        onPause={() => {
          if (startedAt) {
            const now = new Date();
            const sec = Math.abs(differenceInSeconds(startedAt, now));
            setSecondsWatched(sec + secondsWatched)
          }
        }}
      />
    </Grid>
  )
});