import { Grid, Image, Progress, Text, useDisclosure } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { Challenge, challengesStore } from "../store/ChallengesStore";
import checkedIcon from '../assets/checked-icon.svg';
import unCheckedIcon from '../assets/unchecked-icon.svg';
import clockIcon from '../assets/clock-icon.svg';
import giftIcon from '../assets/gift-icon.svg';
import chevronRight from '../assets/chevron-right.svg'
import { format } from "date-fns";
import { LangObj } from "../shared";
import { VideoModal } from "./VideoModal";

const ln: { [key: string]: LangObj } = {
  presentedBy: {
    en: 'Presented by',
    es: 'Presentado por'
  },
  points: {
    en: 'Points',
    es: 'Puntos'
  },
  seeMore: {
    en: 'See more',
    es: 'Ver más'
  }
}

export const HudChallenge: React.FC<{ lang: string }> = observer(({ lang }) => {
  const user = authStore.user;
  useEffect(() => {
    (async () => {
      if (user?.id) {
        await challengesStore.getChallenges(user.id, lang);
      } else {
        authStore.getUser();
      }
    })();
  }, [user, lang])
  const challenges = challengesStore.challenges;
  return (
    <Grid gap="1em">
      {
        challenges.map((c) => <Card lang={lang} challenge={c} key={c.id} />)
      }
    </Grid>
  )
})

const Card: React.FC<{ challenge: Challenge; lang: string }> = ({ challenge, lang }) => {
  const bgColor = challenge.tags.find((t) => t.includes('color'))?.split('-')[1];
  const totalCompleted = challenge.progress.criteria.reduce((acc, c) => acc += c.current, 0);
  const totalCriteria = challenge.progress.criteria.reduce((acc, c) => acc += c.threshold, 0);
  const percentage = totalCompleted > 0 ? 100 * totalCompleted / totalCriteria : 0;
  const {isOpen, onClose, onOpen} = useDisclosure()
  const handlePress = useCallback(() => {
    if (challenge.url && challenge.url.includes('v=')) {
      onOpen();
    }
  }, [challenge])
  return (
    <Grid borderRadius="10px" overflow="hidden" onClick={handlePress} >
      {
        //Header
      }
      <Grid p="1em" bg={bgColor} templateColumns="1fr 1fr">
        <Text variant="white" fontSize="18px">{challenge.title}</Text>
        <Grid gap="8px" placeSelf="start end">
          <Text variant="white" fontSize="11px">{ln.presentedBy[lang]}</Text>
          <Image maxW="123px" src={challenge.image.medium} />
        </Grid>
      </Grid>
      {
        // body
      }
      <Grid p="1em" bg="canelaMain.600">
        <Grid gap="0.5em">
          {
            challenge.progress.criteria.map((c) => {
              return (
                <Grid key={c.message.replaceAll(' ', '-')} gap="8px" templateColumns="auto 1fr">
                  <Image src={c.is_completed ? checkedIcon : unCheckedIcon} w="15px" />
                  <Text variant="white" fontSize="12px">{c.message}</Text>
                </Grid>
              )
            })
          }
        </Grid>
        <Grid mt="1em" templateColumns="1fr 1fr">
          <Text fontSize="22px" variant="white">{Math.round(percentage)}%</Text>
          <Text placeSelf="end" variant="white" fontSize="12px">{totalCompleted}/{challenge.progress.criteria.length}</Text>
        </Grid>
        <Progress m="1em 0" variant="canela" bgColor="canelaLight.700" rounded="1em" value={percentage} />
      </Grid>
      {
        // Footer
      }
      <Grid p="0.5em 1em" bg="black" gridAutoFlow="column">
        <Grid alignItems="center" gap="0.5em" templateColumns="auto 1fr">
          <Image w="12px" src={clockIcon} />
          <Text fontSize="14px" variant="white">{format(new Date(challenge.ends_at), 'MM/dd')}</Text>
        </Grid>
        <Grid alignItems="center" gap="0.5em" templateColumns="auto 1fr">
          <Image w="12px" src={giftIcon} />
          <Text fontSize="14px" fontWeight="700" variant="white">{challenge.points} {ln.points[lang]}</Text>
        </Grid>
        <Grid placeSelf="end" alignItems="center" gap="0.5em" templateColumns="1fr auto">
          <Text fontSize="14px" fontWeight="500" variant="white">{ln.seeMore[lang]}</Text>
          <Image w="8px" src={chevronRight} />
        </Grid>
      </Grid>
      {challenge.url && <VideoModal isOpen={isOpen} onClose={onClose} urls={challenge.url} />}
    </Grid>
  )
}