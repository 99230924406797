import { Box, Button, Grid, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { authStore, User } from "../store/AuthStore";
import { Reward, rewardsStore } from "../store/RewardsStore";
import { observer } from "mobx-react-lite";
import ChevronRightIcon from '../assets/chevron-right.svg';
import { LangObj } from "../shared";

const colorMap = [
  undefined,
  '#CC0000',
  '#D9D9D9',
]

const ln: { [key: string]: LangObj } = {
  title: {
    es: 'Tienda',
    en: 'Store',
  },
  subTitle: {
    es: 'Cambia tus puntos por increíbles recompensas',
    en: 'Redeem your points for amazing rewards'
  },
  giftCards: {
    es: 'Tarjetas de regalo',
    en: 'Gift cards'
  },
  merchandise: {
    es: 'Mercancía oficial',
    en: 'Official merchandise'
  },
  digitalRewards: {
    es: 'Recompensas digitales',
    en: 'Digital rewards',
  },
  more: {
    es: 'Más',
    en: 'More'
  },
  claimReward: {
    es: 'Reclamar Reconpensa',
    en: 'Claim Reward',
  },
  price: {
    es: 'Precio',
    en: 'Price'
  },
  points: {
    en: 'Points',
    es: 'Puntos'
  },
  close: {
    en: 'Close',
    es: 'Cerrar'
  },
  claim: {
    en: 'Claim',
    es: 'Reclamar'
  },
  redeemablePoints: {
    en: 'Redeemable Points',
    es: 'Puntos Canjeables',
  },
  insufficientPoints: {
    en: 'Insufficient Points',
    es: 'Puntos Insuficientes'
  }

}

export const StoreHub: React.FC<{ lang: string }> = observer(({ lang }) => {
  const user = authStore.user;
  const [selectedReward, setSelectedReward] = useState<Reward | undefined>(undefined);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (user?.id) {
      rewardsStore.getRewards(user.id, lang);
      authStore.reFetchUser(user.id, lang);
    } else {
      authStore.getUser();
    }
  }, [user?.id, lang])
  const rewards = rewardsStore.rewards;
  const coupons = rewards.filter((r) => r.type === 'coupon');
  const physical = rewards.filter((r) => r.type === 'physical');
  const content = rewards.filter((r) => r.type === 'content');  
  return (
    <Grid overflow="hidden">
      {user && 
      <RewardModal
        lang={lang}
        reward={selectedReward}
        user={user}
        onClose={() => setSelectedReward(undefined)}
        isLoading={isLoading}
        onClaim={async (r) => {
          setLoading(true)
          await rewardsStore.claimReward(user.id, r.id);
          await authStore.reFetchUser(user.id);
          setLoading(false)
          setSelectedReward(undefined);
        }}
      />}
      <Grid>
        <Text fontSize="32px" variant="white">{ln.title[lang]}</Text>
        <Text variant="white">{ln.subTitle[lang]}</Text>
      </Grid>
      <SectionDivider title={ln.giftCards[lang]} moreText={ln.more[lang]} />
      <Grid mt="1em" maxW="100%" overflow="auto" autoFlow="column" gap="1.5em">
        {coupons.map((c, i) => <Card key={c.id} reward={c} bg={colorMap[i]} onPress={(r) => setSelectedReward(r)} />)}
      </Grid>
      <SectionDivider title={ln.merchandise[lang]} moreText={ln.more[lang]} />
      <Grid mt="1em" maxW="100%" overflow="auto" autoFlow="column" gap="1.5em">
        {physical.map((c) => <Card key={c.id} reward={c} onPress={(r) => setSelectedReward(r)}/>)}
      </Grid>
      <SectionDivider title={ln.digitalRewards[lang]} moreText={ln.more[lang]} />
      <Grid mt="1em" maxW="100%" overflow="auto" autoFlow="column" gap="1.5em">
        {content.map((c) => <Card key={c.id} reward={c} onPress={(r) => setSelectedReward(r)}/>)}
      </Grid>
    </Grid>
  );
})

const SectionDivider: React.FC<{ title: string, moreText: string }> = ({ title, moreText }) => {
  return (
    <Grid mt="1em" templateColumns="1fr auto">
      <Text fontSize="18px" variant="white">{title}</Text>
      <Grid alignItems="center" gap="8px" placeSelf="end" templateColumns="1fr 1fr">
        <Text variant="white" fontSize="12">{moreText}</Text>
        <Image src={ChevronRightIcon} w="5px" />
      </Grid>
    </Grid>
  )
}
type CardProps = {
  reward: Reward;
  bg?: string;
  onPress?: (arg: Reward) => void;
}
const Card: React.FC<CardProps> = ({ reward, bg, onPress }) => {
  return (
    <Grid onClick={() => onPress?.(reward)}>
      <Grid placeSelf="center" bg={bg} borderRadius="50%" w="120px" h="120px">
        <Image w="133px" src={reward.image.medium} />
      </Grid>
      <Text textAlign="center" fontSize="14px" variant="white">{reward.title}</Text>
      <Text textAlign="center" fontSize="15px" fontWeight="900" color="canelaMain.700">{reward.points} PTS</Text>
    </Grid>
  )
}

type RewardModalProps = {
  reward?: Reward;
  onClose: () => void;
  user: User;
  onClaim: (arg: Reward) => void
  isLoading: boolean;
  lang: string;
}

const RewardModal: React.FC<RewardModalProps> = ({ reward, onClose, user, onClaim, isLoading, lang }) => {
  const hasEnoughPoints = user.spendable >= Number(reward?.points);
  return (
    <Modal isOpen={!!reward} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minH="60%" placeSelf="end" bg="canelaLight.700">
          <ModalHeader color="white">{ln.claimReward[lang]}</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody bg="canelaMain.500">
            {reward && 
              <Grid minH="300px" placeContent="center">
                <Box bg="canelaMain.500" p="0.5em" borderRadius="10px">
                  <Card reward={reward} />
                </Box>
                <Grid gap="0.5em" mt="1em">
                  <Text fontSize="14px" variant="white">{ln.redeemablePoints[lang]}: {user.spendable}</Text>
                  <Text fontSize="22px" variant="white">{hasEnoughPoints ? '' : ln.insufficientPoints[lang]}</Text>
                </Grid>
              </Grid>
            }
          </ModalBody>

          <ModalFooter>
            <Button h="45px" bg="canelaLight.700" mr={3} onClick={onClose}>
              {ln.close[lang]}
            </Button>
            <Button
              onClick={() => {
                if (reward) onClaim(reward);
              }}
              isDisabled={!hasEnoughPoints || isLoading}
              opacity={hasEnoughPoints ? 1 : 0.5}
              isLoading={isLoading}
              w="75%"
              h="45px"
              variant="canela"
            >{ln.claim[lang]}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}