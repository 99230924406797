import { Grid, Image, Text } from "@chakra-ui/react";
import React from "react";
import homeImg from '../assets/home-1.svg';
import tvIcon from '../assets/tv_gen.svg';
import searchIcon from '../assets/search.svg';
import userIcon from '../assets/user.svg';

export const BottomMenu: React.FC = () => {
  return (
    <Grid
      position="fixed"
      boxShadow="0px -1px 1px 0px #98989817;"
      gridAutoFlow="column"
      bottom={0}
      right={0}
      left={0}
      zIndex={100}
      bg="canelaMain.500"
      h="70px"
      placeItems="center"
    >
      <Grid justifyItems="center">
        <Image src={homeImg} w="24px" h="24px" />
        <Text variant='menuDisabled'>Inicio</Text>
      </Grid>
      <Grid justifyItems="center">
        <Image src={tvIcon} w="24px" h="24px" />
        <Text variant='menuDisabled'>Canales</Text>
      </Grid>
      <Grid justifyItems="center">
        <Image src={searchIcon} w="24px" h="24px" />
        <Text variant='menuDisabled'>Buscar</Text>
      </Grid>
      <Grid justifyItems="center">
        <Image src={userIcon} w="24px" h="24px" />
        <Text variant='menuEnabled'>Mi Hub</Text>
      </Grid>
    </Grid>
  )
}
