import { Grid, Text, Image, Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { tierMap } from "../shared";

type StringObject = {[key: string]: string}

type TierTrackerProps = {
  lang: string
  iconMap: StringObject;
}

export const TierTracker: React.FC<TierTrackerProps> = observer(({ lang, iconMap }) => {
  const user = authStore.user;
  useEffect(() => {
    if (!user) {
      authStore.getUser();
    }
  }, [ user ]);
  const currentTier = user?.tiers.find((t) => t !== null && t.structure.id === 'canela_tier_st')
  const currentTierIndex = Object.keys(iconMap).findIndex((id) => id === currentTier?.id)
  return (
    <Grid placeContent="space-between" templateColumns="repeat(5, 48px)">
      {
        Object.keys(iconMap).map((id, i) => {
          let borderColor = currentTier?.id === id ? 'canelaMain.700' : 'canelaLight.100';
          if (currentTierIndex < i) {
            borderColor = 'rgba(255,255, 255, 0.5)';
          }
          return (
            <Grid
              border="1px solid"
              borderColor={borderColor}
              borderRadius="50%"
              key={id}
              w="48px"
              h="48px"
              placeContent="center"
              position="relative"
              bg="canelaMain.500"
            >
              <Image opacity={currentTierIndex >= i ? 1 : 0.5} src={iconMap[id]} h="22px" />
              <Text
                fontSize="12px"
                variant="white"
                position="absolute"
                bottom="-24px"
                textAlign="center"
                minW="48px"
              >
                {tierMap[id][lang]}
              </Text>
              {i < 4 && 
                <Box
                  position="absolute"
                  right="-48px"
                  w="48px"
                  h="1px"
                  bg={currentTierIndex > i ? "canelaMain.700" : "canelaLight.300"}
                  top="calc(50% - 1px)"
                  opacity={currentTierIndex > i ? 1 : 0.5}
                />
              }
            </Grid>
          )
        })
      }
    </Grid>
  )
});
