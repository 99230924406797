import { Button, Grid, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { authStore } from "../store/AuthStore";
import { observer } from "mobx-react-lite";
import bronzeIcon from '../assets/bronce.svg';
import silverIcon from '../assets/plata.svg';
import goldIcon from '../assets/oro.svg';
import platinumIcon from '../assets/platino.svg';
import diamondIcon from '../assets/diamante.svg';
import { ProgressSection } from "./ProgressSection";
import { HudChallenge } from "./HudChallenges";
import { LangObj, tierMap } from "../shared";
import { TierTracker } from "./TierTracker";

type iconMapProps = {
  [key: string]: string
}
const iconMap: iconMapProps = {
  cn_bronze: bronzeIcon,
  cn_silver: silverIcon,
  cn_gold: goldIcon,
  cn_platinum: platinumIcon,
  cn_diamond: diamondIcon,
}



const ln: { positionButton: LangObj; rankText: LangObj } = {
  positionButton: {
    es: 'Tabla de posición',
    en: 'Leaderboard'
  },
  rankText: {
    es: 'RANGO',
    en: 'Rank',
  }
}

export const RewardHub: React.FC<{ lang: string }> = observer(({ lang }) => {
  const user = authStore.user;
  useEffect(() => {
    if (!user) {
      authStore.getUser();
    }
  }, [ user ]);  
  const currentTier = user?.tiers.find((t) => t !== null && t.structure.id === 'canela_tier_st')
  
  return (
    <Grid mt=".5em">
      <Grid gap="1em">
        <Grid templateColumns="1fr 1fr">
            <Grid>
              <Grid gap="8px" alignItems="center" placeContent="start" templateColumns="auto auto">
                <Image w="27px" src={iconMap[currentTier?.id || 'cn_bronze']} />
                <Text fontSize="24px" variant="white">{tierMap[currentTier?.id || 'cn_bronze'][lang]}</Text>
              </Grid>
              <Grid placeContent="start" gap="0.3em" templateColumns="auto auto">
                <Text variant="white" opacity={0.5}>{ln.rankText[lang]}#</Text>
                <Text variant="white">{user?.score}</Text>
              </Grid>
            </Grid>
            <Button h="32px" variant="canela">{ln.positionButton[lang]}</Button>
        </Grid>
        <TierTracker iconMap={iconMap} lang={lang} />
        <ProgressSection lang={lang} />
        <HudChallenge lang={lang} />
      </Grid>
    </Grid>
  )
})

