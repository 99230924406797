import {
  createBrowserRouter,
} from "react-router-dom";
import { Login } from './pages/Login';
import { ActivityHistory } from "./pages/ActivityHistory";
import { Rewards } from "./pages/Rewards";
import { MyHub } from "./pages/MyHub";

const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <MyHub />,
    },
    {
      path: '/activity-history',
      element: <ActivityHistory />,
    },
    {
      path: '/rewards',
      element: <Rewards />,
    }
  ]);

export { router }