import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react'

const ButtonStyle: ComponentStyleConfig = {
  // style object for base or default style
  baseStyle: {
    pt: '0.5em',
    pb: '0.5em',
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {},
  // styles for different visual variants ("outline", "solid")
  variants: {
    solid: {
      bg: 'brand.300',
      color: 'brand.100',
      p: '0.5em',
      _hover: {
        bg: 'brand.500',
      },
      _focus: {
        bg: 'brand.500',
      }
    },
    canela: {
      bg: 'canelaMain.700',
      color: 'canelaLight.100',
      p: '0.5em',
      borderRadius: '8px',
      _focus: {
        bg: 'canelaMain.700',
      }
    },
    solidSecondary: {
      bg: 'brand.700',
      color: 'brand.500',
      p: '0.5em',
      _disabled: {
        bg: 'brand.200',
        color: 'brand.100',
        opacity: 0.5
      }
    }
  },
  // default values for 'size', 'variant' and 'colorScheme'
  defaultProps: {
    size: '',
    variant: 'solid',
    colorScheme: 'brand',
  },
}

const LinkStyle: ComponentStyleConfig = {
  defaultProps: {
    variant: 'brand'
  },
  variants: {
    brand: {
      color: 'brand.500'
    },
    white: {
      color: 'brand.100'
    }
  }
}

const ProgressStyle: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'canelaAlt',
    borderRadius: '1em',
  }
}

const TextStyle: ComponentStyleConfig = {
  variants: {
    menuDisabled: {
      color: 'canelaLight.300',
      fontSize: '14px',
      fontWeight: '400',
    },
    menuEnabled: {
      color: 'canelaLight.100',
      fontSize: '14px',
      fontWeight: '400',
    },
    white: {
      color: 'canelaLight.100'
    }
  }
}

const TabStyles: ComponentStyleConfig = {
  defaultProps: {
    _selected: {
      color: 'canelaLight.100'
    }
  }
}

export const theme = extendTheme({
  colors: {
    brand: {
      100: '#FFFFFF',
      200: '#787878',
      300: '#007196',
      500: '#33344C',
      600: '#E6E7E8',
      700: '#ECDB14',
      800: '#D50000',
    },
    brand2: {
      500: '#ECDB14',
    },
    canelaMain: {
      300: '#1A1A1A',
      500: '#0E0B0B',
      600: 'rgba(37, 37, 37, 0.8)',
      700: '#B12028',
      900: '#002D74',
    },
    canelaAlt: {
      500: '#B12028',
    },
    canelaLight: {
      100: '#FFFFFF',
      300: '#838383',
      500: '#ACACAC',
      700: '#3A3A3A',
    }
  },
  components: {
    Button: ButtonStyle,
    Link: LinkStyle,
    Progress: ProgressStyle,
    Text: TextStyle,
    Tab: TabStyles,
  }
});